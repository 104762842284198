import { FaleConoscoComponent } from './fale-conosco/fale-conosco.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SobreComponent } from './sobre/sobre.component';
import { FaqComponent } from './faq/faq.component';
import { PoliticaPrivacidadeComponent } from './politica-privacidade/politica-privacidade.component';
import { TermoCondicoesComponent } from './termo-condicoes/termo-condicoes.component';

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    { path: 'fale-conosco', component: FaleConoscoComponent },
    { path: 'sobre', component: SobreComponent },
    { path: 'faq', component: FaqComponent },
    { path: 'politica-privacidade', component: PoliticaPrivacidadeComponent },
    { path: 'termos-condicoes', component: TermoCondicoesComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(
        routes,
        {
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled'
        },
    )],
    exports: [RouterModule]
})
export class AppRoutingModule { }
