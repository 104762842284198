import { AfterViewInit, OnInit, Component } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { fromEvent } from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    map,
    pairwise,
    share,
    throttleTime
} from 'rxjs/operators';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, AfterViewInit {
    public currentPosition: number;
    public currentAnchor: string;
    public anchors: Array<string> = new Array<string>();
    public sectionId: string;
    showModal: boolean = false;

    constructor(private router: Router) {}

    ngOnInit() {
        this.anchors.push('secao-1');
        this.anchors.push('secao-2');
        this.anchors.push('secao-3');
        this.anchors.push('secao-4');
        this.currentAnchor = this.anchors[0];
    }

    ngAfterViewInit() {
        const scroll$ = fromEvent(window, 'scroll').pipe(
            throttleTime(10),
            map(() => window.pageYOffset),
            pairwise(),
            map(([y1, y2]) => {
                return (y1 === 0 || y2 === 0);
            }),
            distinctUntilChanged(),
            share()
        );

        scroll$.subscribe((retorno) => retorno ? this.currentAnchor = this.anchors[0] : null);
    }

    mouseWheelUpFunc(event) {
        const indexAnchor = this.anchors.indexOf(this.currentAnchor);
        if (indexAnchor > 0) {
            this.currentAnchor = this.anchors[this.anchors.indexOf(this.currentAnchor) - 1];
        }
    }

    mouseWheelDownFunc(event) {
        const indexAnchor = this.anchors.indexOf(this.currentAnchor);
        if (indexAnchor < this.anchors.length - 1) {
            this.currentAnchor = this.anchors[this.anchors.indexOf(this.currentAnchor) + 1];
        }
    }

    goSecao(anchor: string): void {
        let navigateExtras: NavigationExtras = {
            fragment: anchor
        }
        this.router.navigate(['home'], navigateExtras);
    }

    exibirModal() {
        this.showModal = !this.showModal;
    }
}
