
import { FormControl, FormGroup } from '@angular/forms';

export class EmailValidator {

    static validarFormatoEmail(control: FormControl): ValidationResult {
        return control.value && !EmailValidator.validarEmail(control.value) ? { 'emailFormatoIncorreto': true } : null;
    }

    static validarEmail(email: string): boolean {
        const EMAIL_REGEXP = /^[a-zA-Z0-9][a-zA-Z0-9\._-]+@([a-zA-Z0-9\._-]+\.)[a-zA-Z-0-9]{2,4}$/;
        return email ? (EMAIL_REGEXP.test(email) ? true : false) : false;
    }

    static validarConfirmacaoEmail(emailKey: string, confirmacaoEmailKey: string) {
        return (group: FormGroup): ValidationResult => {
            const email = group.controls[emailKey];
            const confirmacaoEmail = group.controls[confirmacaoEmailKey];
            if (email.value && confirmacaoEmail.value && email.value !== confirmacaoEmail.value) {
                return { 'emailDivergente': true };
            }
        };
    }

}

interface ValidationResult {
    [key: string]: boolean;
}
