import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termo-condicoes',
  templateUrl: './termo-condicoes.component.html'
})
export class TermoCondicoesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
