import { MouseWheelDirective } from './mouse-whell.directive';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SubmitLoaderDirective } from './loader/submit-loader.directive';

@NgModule({
    declarations: [
        MouseWheelDirective,
        SubmitLoaderDirective
    ],
    imports: [
        BrowserModule
    ],
    exports: [
        MouseWheelDirective,
        SubmitLoaderDirective
    ]
})
export class SharedModule { }
