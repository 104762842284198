import { Component, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-scroll-lateral',
    templateUrl: './scroll-lateral.component.html'
})
export class ScrollLateralComponent implements OnInit, OnChanges {
    @Input()
    selected: string;
    @Input()
    anchors: Array<string>;
    constructor(private activatedRouter: ActivatedRoute, private router: Router) {
        this.activatedRouter.fragment.subscribe(anchor => {
            this.selected = anchor;
        });
    }
    ngOnInit() {
        this.router.navigate(['/home'], { fragment: this.selected});
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['selected'] && changes['selected'].currentValue !== changes['selected'].previousValue) {
            this.mudar(this.selected);
        }
    }

    mudar(anchor: string) {
        this.selected = anchor;
        this.router.navigate(['/home'], { fragment: anchor});
    }
}
