import { AfterViewInit, Component, HostBinding } from '@angular/core';
import { fromEvent } from 'rxjs';

import {
    animate,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';

import {
    distinctUntilChanged,
    filter,
    map,
    pairwise,
    share,
    throttleTime
} from 'rxjs/operators';

enum VisibilityState {
    Visible = 'visible',
    Hidden = 'hidden'
}

enum Direction {
    Up = 'Up',
    Down = 'Down'
}

const offSet = 100;

@Component({
    selector: 'app-scroll-hide',
    template: `<ng-content></ng-content>`,
    styles: [
        `
        :host {
            position: fixed;
        }
        `
    ],
    animations: [
        trigger('toggle', [
        state(
            VisibilityState.Hidden,
            style({ opacity: 0, transform: 'translateY(100%)' })
        ),
        state(
            VisibilityState.Visible,
            style({ opacity: 1, transform: 'translateY(0)' })
        ),
        transition('* => *', animate('200ms ease-in'))
        ])
    ]
})

export class ScrollHideComponent implements AfterViewInit {
    private isVisible = false;

    @HostBinding('@toggle')
    get toggle(): VisibilityState {
        return this.isVisible ? VisibilityState.Visible : VisibilityState.Hidden;
    }

    ngAfterViewInit() {
        const scroll$ = fromEvent(window, 'scroll').pipe(
            throttleTime(10),
            map(() => window.pageYOffset),
            pairwise(),
            map(([y1, y2]) => y1 > offSet || y2 > offSet ? true : false),
            distinctUntilChanged(),
            share()
        );

        const goingUp$ = scroll$.pipe(
            filter(direction => this.isVisible = direction)
        );

        goingUp$.subscribe(() => (this.isVisible));
    }
}
