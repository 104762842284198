import { EmailValidator } from './../shared/validators/email.validator';
import { FaleConoscoService } from './fale-conosco.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Loader } from '../shared/loader/loader';
import { FaleConosco } from './fale-conosco.model';
import { SelectItem } from 'primeng/api';

const MSG_SUCESSO = 'Mensagem enviada com sucesso!';
const MSG_ERRO = 'Erro ao enviar mensagem. Tente novamente.';

enum TipoRetorno {
    SUCESSO,
    ERRO
}

@Component({
    selector: 'app-fale-conosco',
    templateUrl: './fale-conosco.component.html'
})
export class FaleConoscoComponent implements OnInit {
    TipoRetorno = TipoRetorno;
    formFale: FormGroup;
    enviarLoader: Loader;
    dados: FaleConosco;
    mensagem: string;
    opcoesDropdown: SelectItem[];
    loader = false;
    tipoRetorno: TipoRetorno;

    constructor(private fb: FormBuilder, private faleConoscoService: FaleConoscoService) {
        this.opcoesDropdown = [
            { label: 'Abra sua conta', value: 'Abra sua conta' },
            { label: 'Conta jurídica', value: 'Conta jurídica' },
            { label: 'Cartão internacional', value: 'Cartão internacional' },
            { label: 'Máquina de cartão', value: 'Máquina de cartão' },
            { label: 'Reclamações', value: 'Reclamações' },
            { label: 'Sugestões', value: 'Sugestões' }
        ];
     }

    ngOnInit() {
        this.dados = new FaleConosco();
        this.formFale = this.fb.group({
            motivo: ['', Validators.required],
            nome: ['', [Validators.required, Validators.minLength(8)]],
            email: ['', [Validators.required, EmailValidator.validarFormatoEmail]],
            mensagem: ['', [Validators.required, Validators.minLength(20)]]
        });

        this.enviarLoader = new Loader(
            () => {
                this.mensagem = null;

                if (this.formFale.valid) {
                    return this.faleConoscoService.enviar(this.dados);
                }
                return;
            },
            () => {
                this.mensagem = MSG_SUCESSO;
                this.formFale.reset();
                this.dados = new FaleConosco();
                this.tipoRetorno = TipoRetorno.SUCESSO;
            },
            () => {
                this.mensagem = MSG_ERRO;
                this.tipoRetorno = TipoRetorno.ERRO;
            }
        );
    }

    get classAlert() {
        return {
            'success': this.tipoRetorno === TipoRetorno.SUCESSO,
            'error': this.tipoRetorno === TipoRetorno.ERRO
        }
    }

    isInvalid(controlName: string): boolean {
        const control = this.formFale.get(controlName);
        return control.invalid && (control.dirty || control.touched);
    }

    hasError(controlName: string, errorName: string): boolean {
        const control = this.formFale.get(controlName);
        return control.errors[errorName];
    }
}
