import { Directive, ContentChildren, Input, Renderer, ElementRef  } from '@angular/core';
import { Loader } from './loader';

@Directive({
    // tslint:disable-next-line:use-host-property-decorator
    host: {
        '(submit)': 'submit($event)',
    },
    // tslint:disable-next-line:directive-selector
    selector: '[submit-loader]'
})
export class SubmitLoaderDirective {

    private nativeElement: Node;
    private loaderElement: Node;

    @Input()
    loader: Loader;

    @ContentChildren('submit')
    submitButton;

    constructor(private renderer: Renderer, private element: ElementRef) {
        this.nativeElement = element.nativeElement;
    }

    submit(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this.loader.process(
            () => this.showLoading(),
            () => this.hideLoading(),
            () => this.hideLoading(),
            null
        );
    }

    showLoading() {
        let divElement = this.renderer.createElement(this.nativeElement, 'div');
        this.renderer.setElementClass(divElement, 'loader', true);
        this.loaderElement = divElement;

        let containerBotao = this.submitButton.first.nativeElement.parentNode;

        containerBotao.appendChild(divElement);

        this.submitButton.first.nativeElement.setAttribute('disabled', 'disabled');
    }

    hideLoading() {
        let containerBotao = this.submitButton.first.nativeElement.parentNode;
        if (this.loaderElement.parentElement) {
            containerBotao.removeChild(this.loaderElement);
        }

        this.submitButton.first.nativeElement.removeAttribute('disabled', 'disabled');
    }
}
