import { Directive, Output, HostListener, EventEmitter } from '@angular/core';

// tslint:disable-next-line:directive-selector
@Directive({ selector: '[mouseWheel]' })
export class MouseWheelDirective {
    @Output() mouseWheelUp = new EventEmitter();
    @Output() mouseWheelDown = new EventEmitter();
    private contador = 0;

    @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
        setTimeout(() => this.mouseWheelFunc(event), 200);
        this.contador = 0;
    }

    @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
        setTimeout(() => this.mouseWheelFunc(event), 200);
        this.contador = 0;
    }

    @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
        setTimeout(() => this.mouseWheelFunc(event), 200);
        this.contador = 0;
    }

    mouseWheelFunc(event: any) {
        if (this.contador === 0) {
            this.contador++;
            var event = window.event || event; // old IE support
            var delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));
            if (delta > 0) {
                this.mouseWheelUp.emit(event);
            } else if (delta < 0) {
                this.mouseWheelDown.emit(event);
            }
            // for IE
            event.returnValue = false;
            // for Chrome and Firefox
            if (event.preventDefault) {
                event.stopPropagation();
            }
        } else {
            event.stopPropagation();
        }
    }
}
