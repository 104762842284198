import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FaleConoscoComponent } from './fale-conosco/fale-conosco.component';
import { FaqComponent } from './faq/faq.component';
import { ScrollLateralComponent } from './home/components/scroll-lateral/scroll-lateral.component';
import { HomeComponent } from './home/home.component';
import { LayoutComponent } from './layout/layout.component';
import { PoliticaPrivacidadeComponent } from './politica-privacidade/politica-privacidade.component';
import { ScrollHideComponent } from './shared/scroll.hide.component';
import { SharedModule } from './shared/shared.module';
import { SobreComponent } from './sobre/sobre.component';
import { TermoCondicoesComponent } from './termo-condicoes/termo-condicoes.component';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HomeComponent,
    FaleConoscoComponent,
    ScrollLateralComponent,
    SobreComponent,
    FaqComponent,
    PoliticaPrivacidadeComponent,
    ScrollHideComponent,
    TermoCondicoesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
// tslint:disable-next-line: deprecation
    FileUploadModule,
    DropdownModule
  ],
  providers: [  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
