import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FaleConosco } from './fale-conosco.model';

@Injectable({ providedIn: 'root' })
export class FaleConoscoService {
    private url = '/email/faleconosco';
// tslint:disable-next-line: deprecation
    private headers = new HttpHeaders({
        'Content-Type': 'application/json'
    });
    // tslint:disable-next-line: deprecation
    constructor(private http: HttpClient) { }

    enviar(dados: FaleConosco): Observable<any> {
        return this.http.post(this.url, dados, { headers: this.headers });
    }
}
