import { Model } from './../shared/model/model';
export class FaleConosco extends Model {
    motivo: string;
    nome: string;
    email: string;
    mensagem: string;

    constructor(objeto?) {
        super(objeto);
    }
}
