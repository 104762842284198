import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html'
})
export class FaqComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
