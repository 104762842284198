import { Subscription, Observable } from 'rxjs';

type CallbackSuccess = (value: any) => void;

type CallbackComplete = () => void;

type CallbackError = (err: any) => void;

export class Loader {

    private subscription: Subscription;

    constructor(private action: () => Observable<any>,
        private success?: CallbackSuccess,
        private error?: CallbackError,
        private complete?: CallbackComplete) { }


    process(onStart: () => void,
        onSuccess: CallbackSuccess,
        onError: CallbackError,
        onComplete: CallbackComplete) {

        const observable = this.action();

        if (observable) {
            onStart();

            this.subscription = observable.subscribe(
                (value) => {
                    this.executeFn(this.success, value);
                    this.executeFn(onSuccess, value);
                },
                (error) => {
                    this.executeFn(this.error, error);
                    this.executeFn(onError, error);
                    this.subscription = null;
                },
                () => {
                    this.executeFn(this.complete);
                    this.executeFn(onComplete);
                    this.subscription = null;
                }
            );
        }
    }

    run() {
        const observable = this.action();

        if (observable) {

            this.subscription = observable.subscribe(
                (value) => this.executeFn(this.success, value),
                (error) => {
                    this.executeFn(this.error, error);
                    this.subscription = null;
                },
                () => {
                    this.executeFn(this.complete);
                    this.subscription = null;
                }
            );
        }
    }

    cancel() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    private executeFn(fn: any, args?: any) {
        if (fn) {
            fn(args);
        }
    }
}

