import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html'
})
export class LayoutComponent implements OnInit {

    showMenu: boolean = false;
    showModal: boolean = false;
    modalTrabalheConosco: boolean = false;

    constructor(private router: Router) { }

    ngOnInit() {
    }

    toggleMenu(): void {
        this.showMenu = !this.showMenu;
    }

    exibirModal() {
        this.showModal = !this.showModal;
    }

    modalCurriculo() {
        this.modalTrabalheConosco = !this.modalTrabalheConosco;
    }

    scrollTop() {
        window.scrollTo(0, 0);
    }

    goSecao(anchor: string): void {
        let navigateExtras: NavigationExtras = {
            fragment: anchor
        }
        this.router.navigate(['home'], navigateExtras);
    }
}
